@import '../../../../../../../style/variable.scss';

.numberCode {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  grid-area: c;
  grid-row: 3/4;
  align-self: end;
}

.numberCode input {
  width: 64px;
  height: 64px;
  border-radius: 6px;
  margin-right: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: var(--itemtext);
  font-family: var(--fontfamilyTwo);
  background: var(--poblick) !important;
  border: 1px solid var(--itemBorder) !important;
  position: relative;
  transform: translate(0%);
  &.err {
    border: 1px solid red !important;
    animation: error 0.2s ease 0s;
  }

  @keyframes error {
    0% {
      transform: translate(0%);
    }
    25% {
      transform: translate(10%);
    }
    25% {
      transform: translate(-10%);
    }
    100% {
      transform: translate(0%);
    }
  }
}

.numberCode input:last-child {
  margin-right: 0px;
}

@media screen and (max-width: $mw5 + px) {
  .numberCode input {
    width: 54px;
    height: 54px;
    font-size: 18px;
  }
}
