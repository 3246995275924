@import '../../../../../style/variable.scss';

.twofactor {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 71px 90px 89px 60px;
  grid-template-areas: 'a' 'b' 'c' 'd' 'e';
  text-align: start;
  text-align: left;
}

.twofactor__title {
  grid-area: a;
  grid-row: 1/2;
  user-select: none;
  color: var(--itemtext);
}

.twofactor__text {
  grid-area: b;
  grid-row: 2/3;
  align-self: end;
  width: 100%;
}

.twofactor__text p {
  user-select: none;
  color: var(--itemtext);
  max-width: 308px;
  line-height: 25px !important;
}

.twofactor__repeat {
  grid-area: d;
  grid-row: 4/5;
  justify-self: center;
  padding-top: 15px;
  user-select: none;
  font-family: var(--fontFamily);
  color: var(--itemtext);
}

.twofactor__repeat p {
  cursor: pointer;
  text-decoration: underline;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.twofactor__footer {
  grid-area: c;
  grid-row: 5/6;
  align-self: end;
  justify-self: start;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.twofactor__back {
  color: var(--itemtext);
  cursor: pointer;
  transition: color 0.2s ease 0s;
}

.twofactor__back:hover {
  color: var(--line);
}

.twofactor__btn {
  width: 100px;
}

@media screen and (max-width: $mw5 + px) {
  .twofactor {
    width: 100%;
    grid-template-columns: 100%;
  }
}
