@import 'src/style/variable.scss';

.portal {
  position: relative;
  opacity: 0;
  left: auto;
  transform: translateY(100%);
  max-width: 300px;
  display: inline-block;
  width: 100%;
  background: #ffffff;
  border-left: 5px solid grey;
  transition: all 1s ease 0s;
  left: auto;
  margin-bottom: 10px !important;
  display: flex;
  justify-content: end;
  box-shadow: 2px 2px 5px #2929296f;
  bottom: 0;
  &__container {
    position: fixed;
    background: transparent;
    width: 100%;
    bottom: 0;
    z-index: 5;
    pointer-events: none;
    display: flex;
    justify-content: start;
    align-items: end;
    flex-direction: column;
  }

  &.err {
    border-left: 5px solid rgb(255, 24, 24);
  }
  &.show {
    opacity: 1;
    transform: translateY(0%);
  }
  &__body {
    padding: 10px 10px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    pointer-events: all;
    justify-content: space-between;
    p {
      color: var(--oldgray) !important;
    }
  }

  &.basketAddPort,
  &.like {
    border-left: 5px solid green !important;
    background: green !important;
    p {
      color: white !important;
    }
    svg {
      fill: white !important;
    }
  }
  &.basketDelete,
  &.likeDelete {
    background: var(--oldRed) !important;
    border-left: 5px solid var(--oldRed) !important;
    p {
      color: white !important;
    }
    svg {
      fill: white !important;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    p {
      margin-left: 10px;
    }
  }

  &__close,
  &__like,
  &__basket {
    svg {
      cursor: pointer;
      display: block;
      width: 12px;
      height: 12px;
    }
  }
  &__close {
    margin-left: 12px;
    svg {
      fill: var(--oldgray) !important;
    }
  }

  @media (max-width: $mw4 + px) {
    & {
      right: 0 !important;
      top: auto;
      bottom: 0;
      left: 0;
      width: 100%;
      min-width: 100%;
    }
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}
