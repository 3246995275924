@import 'src/style/variable.scss';

@media screen and (max-width: 1100px) {
  .header {
    &__wrap {
      display: flex;
    }
    &__basket_number {
      font-size: 12px;
      width: 15px;
      height: 15px;
      line-height: 15px;
      color: #fff;
      bottom: -2px;
      right: -2px;
    }
  }
}

@media screen and (max-width: 1004px) {
  .header {
    border-bottom: 2px solid var(--borderforschedul);

    &.home {
      border-bottom: 2px solid #434343;
    }

    &.home.show {
      border-bottom: 2px solid var(--borderforschedul) !important;
    }
    &__burger {
      order: 1;
      opacity: 1;
      visibility: visible;
      display: block;
    }

    &__link {
      order: 2;
      position: static;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      display: flex;
    }

    &__link li {
      height: auto;
    }
    &__link li a {
      align-items: center;
      height: auto;
      padding: 11px 0;
    }
    &__wrap {
      justify-content: space-between;
    }
    &__links {
      display: none;
    }
  }
}

@media (max-width: $mw4 + px) {
  .header {
    &__logoText {
      display: none;
    }
    &__wrap {
      height: 65px;
    }
    &__logo {
      height: 100%;
    }
    &__logoImg img {
      width: 40px;
      height: 40px;
    }
  }
}
@media screen and (max-width: $mw5 + px) {
  .header {
    &__logo {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &__logoImg img {
      width: 40px;
      height: 40px;
      transition: all 0.5s ease 0s;
    }
    &__logoImg {
      padding: 0;
    }
    &__logoText {
      display: none !important;
    }
  }
}
