@import 'src/style/variable.scss';

.blockicons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__parentblock.heartIcon,
  &__profile,
  &__sign li:first-child,
  &__theme {
    margin-right: 25px;
  }

  &__items {
    height: 100%;
  }
  &__elements {
    display: flex;
    height: 100%;
    align-items: center;
  }
  &__theme,
  &__heart,
  &__profile,
  &__basket {
    display: flex;
    align-items: center;
  }

  &__parentblock {
    position: relative;
    cursor: pointer;
    height: 25px;
    display: flex;
    align-items: center;
  }
  &__profile {
    align-items: center;
    cursor: pointer;
  }
  &__number {
    pointer-events: none;
    font-size: 12px;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -10px;
    right: -10px;
    line-height: 16px;
    border: 2px solid var(--poblick) !important;
    background: var(--searchBlock) !important;
    border-radius: 100%;
    color: var(--poblick) !important;
    user-select: none;
    display: none;
    justify-content: center;
    align-items: center;

    &.active {
      display: flex;
    }
  }

  &__heart {
    display: flex;
    cursor: pointer;
  }

  &__theme {
    position: relative;
    width: 30px;
    height: 30px;
    transition: all 1s ease 0;
    border-radius: 100%;
    cursor: pointer;
    overflow: hidden;
    transition: background 0.2s ease 0s;

    &:hover {
      background: var(--gray);
    }
  }

  &__theme > div {
    position: absolute;
    display: flex;
    left: 0%;
    transition: left 0.5s ease 0s;
    width: 60px;
    top: 0;
    align-items: center;
    height: 100%;
  }

  &__theme.changeTheme div {
    left: -100%;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &__theme {
      display: none;
      opacity: 0;
      visibility: hidden;
    }
  }
  &__moon,
  &__sun {
    @include blocksvg(30px, 30px, 20px, 20px, var(--searchBlock));
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__profile svg {
    @include svg(18px, 20px, var(--searchBlock));
  }

  &__heart svg {
    @include svg(18px, 22px, var(--searchBlock));
  }

  &__basket svg {
    @include svg(25px, 25px, var(--searchBlock));
    transform: scaleX(-1);
  }

  &.show &__heart,
  &.show &__basket,
  &.show &__profile,
  &.show &__links,
  &.show &__logo {
    color: #fff;
  }

  &__sign {
    justify-content: center;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
  }
  &__sign li {
    justify-content: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    user-select: none;
  }
  &__sign a {
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    font-family: var(--fontFamily);
    color: var(--searchBlock) !important;
    font-size: 18px;
    transition: color 0.2s ease 0s;
  }

  &__sign a:after {
    content: '';
    position: absolute;
    bottom: 5px;
    transition: bottom 0.5s ease 0s, opacity 0.2s ease 0s;
    width: 100%;
    z-index: -1;
    opacity: 0;
    background: var(--oldRed) !important;
    justify-content: center;
    display: flex;
    height: 2px;
    left: 0;
  }
  &__sign a:hover:after {
    opacity: 1;
    bottom: -5px;
  }
}

.header {
  &.home .blockicons svg,
  &.home .blockicons__sign a {
    fill: #fff !important;
    color: #fff !important;
  }

  &.home.show .blockicons svg,
  &.home.show .blockicons__sign a {
    fill: var(--searchBlock) !important;
    color: var(--searchBlock) !important;
  }

  &.home .blockicons__number {
    border: 2px solid var(--oldgray) !important;
    background: #fff !important;
    color: var(--oldgray) !important;
  }

  &.home.show .blockicons__number {
    border: 2px solid var(--poblick) !important;
    background: var(--searchBlock) !important;
    color: var(--poblick) !important;
  }

  .blockicons__sign a:hover {
    color: var(--oldRed) !important;
  }

  &.home .blockicons__theme:hover {
    background: var(--oldgray) !important;
  }
  &.home.show .blockicons__theme:hover {
    background: var(--gray) !important;
  }
}

@media screen and (max-width: 1004px) {
  .blockicons {
    &__theme {
      background: var(--oldgray) !important;
    }
    &__parentblock.heart,
    &__profile,
    &__theme {
      margin-right: 20px;
    }
    &__sign li:first-child {
      margin-right: 20px;
    }
  }
  .header {
    svg {
      fill: #fff !important;
    }
  }

  .header.show {
    svg {
      fill: #fff !important;
    }

    &__theme {
      width: 28px;
      height: 28px;
    }

    &__moon,
    &__sun {
      width: 28px;
    }

    &__profile svg {
      width: 20px;
    }

    &__heart svg {
      width: 24px;
    }

    &__moon svg,
    &__sun svg {
      width: 22px;
      height: 22px;
    }

    &__basket svg {
      transform: scaleX(-1);
    }

    &__elements {
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      order: 1;
    }

    &__sign li a {
      align-items: center;
      height: auto;
      line-height: 130%;
    }

    &__sign li:after {
      transition: 0s ease 0s;
    }

    &__sign li:active:after {
      opacity: 1;
      height: 100%;
    }
  }
}

@media screen and (max-width: $mw1 + px) {
  .blockicons {
    &__parentblock.heartIcon,
    &__parentblock.heart,
    &__profile,
    &__theme {
      margin-right: 20px !important;
    }
  }
}
@media screen and (max-width: $mw5 + px) {
  .blockicons {
    &__parentblock.heartIcon,
    &__parentblock.heart,
    &__profile,
    &__theme {
      margin-right: 20px;
    }
    &__sign li:first-child {
      margin-right: 20px;
    }
  }
}
