@import '../../../style/variable.scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  transition: all 0.5s ease 0s;
  z-index: 1043;
  height: 100vh !important;
  max-height: 100vh;
  width: 100%;
  &__back {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(8px);
  }
  &.active,
  &__back.active {
    opacity: 1;
    visibility: visible;
  }
  &__bg {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    box-sizing: border-box;

    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
  }
  &__container {
    width: 100%;
    display: inline-block;
    margin: 0 auto;
    vertical-align: middle;
  }

  &__body {
    cursor: auto;
    margin: 40px auto;
    border: 1px solid gray;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: var(--gray);
    overflow: hidden;
    border-radius: 5px;
    transition: transform 0.5s ease 0s, opacity 0.5s ease 0s;
    transform: scale(2);
    opacity: 0;
    width: 500px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  }
  &__container.other &__body {
    width: 702px;
  }
  &.active {
    .modal__body {
      opacity: 1;
      transform: scale(1);
    }
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &__back {
      background: #0000008b;
    }
  }
  @media screen and (max-width: 710px) {
    &__container.other &__body {
      width: 100%;
    }
  }
  @media screen and (max-width: 490px) {
    &__container.sing {
      height: 100%;
    }
    .modal__body {
      border: none;
      height: 100%;
      justify-content: start;
      margin: 0;
      border-radius: 0;
    }
    &__body {
      width: 100% !important;
    }
    &__container.up {
      display: block;
    }
  }
}
