// Шрифты
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

$mw: 1400;
$mw1: calc($mw + 12);
$mw2: 1100;
$mw3: calc(992 + 12);
$mw4: 769;
$mw5: 479;

%load {
  background-image: url('../img/icons/loading.gif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  img {
    opacity: 0;
    visibility: hidden;
    display: none;
    width: 20px;
    height: 20px;
  }
}

%img {
  opacity: 0;
  visibility: hidden;
  display: none;
  width: 20px;
  height: 20px;
}

@mixin blocksvg($width, $height, $svgW, $svgH, $color) {
  width: $width;
  height: $height;
  svg {
    width: $svgW;
    height: $svgH;
    fill: $color !important;
  }
}

@mixin svg($width, $height, $color) {
  width: $width;
  height: $height;
  fill: $color !important;
}

html {
  --homeBanner: linear-gradient(
    to top,
    #ffffff,
    transparent,
    transparent,
    transparent,
    transparent
  );
  --thumbcircle: linear-gradient(
    to bottom right,
    rgba(41, 41, 255, 1) 0%,
    rgba(91, 91, 255, 1) 92%
  );
  --headerline: #000;
  --bgcolorLocal: rgba(255, 255, 255, 0.9);
  --sortBlock: #cccccc;
  --borderforschedul: #e6e6e6;
  --shadowbasket: #e6e6e6;
  --header: #fff;
  --borderInput: #959595;
  --brand-name-shop: #959595;
  --shadow: 0 0 5px #292929;
  --poblick: #fff;
  --gray: rgb(237, 237, 237) !important;
  --yellow: #b03d26;
  --red: #fff;
  --searchBlock: #292929;
  --line: #292929;
  --goodsBack: linear-gradient(
    to top left,
    rgb(237, 237, 237),
    rgb(255, 255, 255)
  );
  //   --price: linear-gradient(to top left, #feeda9, rgb(209, 209, 209) 80%);
  --bottomItem: rgb(237, 237, 237) !important;
  --blockitem: rgb(237, 237, 237) !important;
  --itemtext: #3d3d3d !important;
  --itemBorder: #b1b1b1 !important;
  --placeholder: #a6a6a6 !important;
  --itemBack: rgb(211, 211, 211) !important;

  // fonts
  --fontTitle: 'Oswald', sans-serif;
  --fontFamily: 'Poppins', sans-serif;
  --fontFamily2: 'Anton';

  // not change color
  --oldRed: #b03d26 !important;
  --oldyellow: #f7ca18 !important;
  --oldyellow_2: #ffee00 !important;
  --oldwhite: #f5eadb !important;
  --oldgray: #292929 !important;

  // wight default
}
html body.changeTheme {
  --thumbcircle: rgb(41, 41, 255);
  --line: #878787 !important;
  --shadow: 0 0 5px rgba(255, 255, 255, 0.644) !important;
  --homeBanner: linear-gradient(
    to top,
    #292929,
    transparent,
    transparent,
    transparent
  ) !important;
  --headerline: var(--oldRed) !important;
  --borderforschedul: #434343 !important;
  --shadowbasket: transparent !important;
  --header: #434343 !important;
  --bgcolorLocal: #292929f9 !important;
  --sortBlock: #4a4949 !important;
  --brand-name-shop: #626262;
  --borderInput: #eaeaea !important;
  --poblick: #292929 !important;
  --gray: #383838 !important;
  --yellow: #ffee00 !important;
  --red: #b03d26 !important;
  --searchBlock: #fff !important;
  --goodsBack: linear-gradient(
    to top left,
    rgb(142, 142, 142),
    rgb(199, 199, 199)
  ) !important;
  --bottomItem: rgb(142, 142, 142) !important;
  --blockitem: #383838 !important;
  --itemtext: rgb(196, 196, 196) !important;
  --itemBorder: #606060 !important;
  --placeholder: #606060 !important;
  --itemBack: #1d1d1d !important;
}
@media (prefers-color-scheme: dark) {
}
