.imgpreload {
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.load {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
