@import 'src/style/variable.scss';

.footer {
  background: rgb(19, 0, 0);
  width: 100%;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  &__logo {
    justify-self: center;
    height: auto;
    display: flex;
    align-items: center;
    user-select: none;
    grid-row: 1/2;
    color: #fff;
  }

  &__logoText {
    line-height: 100%;
    font-size: 35px;
    user-select: none;
    display: block;
    font-family: var(--fontFamily2);
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 0.2s ease 0s;
  }

  &__logoImg {
    padding-right: 10px;
    align-self: start;
  }

  &__logoImg img {
    width: 70px;
    height: 70px;
    display: block;
    transition: all 0.5s ease 0s;
  }

  &__inlinear {
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../../../img/gym/lean.svg');

    &.none {
      display: none;
    }
  }

  &__body {
    display: grid;
    grid-template: auto 1fr auto / 1fr;
    align-items: center;
    height: auto;
    width: 100%;
    opacity: 0;
    padding: 50px 0;
    transition: all 1.5s ease 0s;
    transform: translateY(10px);
    justify-items: center;
  }

  &.show &__body {
    opacity: 1;
    transform: translateY(0px);
  }
  &__text {
    justify-self: center;
    grid-row: 2/3;
    max-width: 300px;
    width: 100%;
    padding: 30px 0;
    text-align: center;

    p {
      color: #d1d1d1 !important;
      line-height: 1.5 !important;
      font-size: 13px;
    }

    P:last-child {
      color: #d1d1d1 !important;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: #fff !important;
      }
    }
  }
  &__items {
    justify-self: center;
    grid-row: 3/4;
    display: grid;
    height: auto;
    grid-template: 1fr / auto auto;
    align-items: center;
    justify-items: end;
  }
  &__icons {
    grid-column: 2/3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
  }

  &__contacts {
    grid-column: 1/2;
    justify-self: start;

    a {
      text-decoration: none;
      color: rgb(193, 193, 193);
      cursor: pointer;
      &:hover {
        color: #fff;
      }
      &:visited {
        color: rgb(193, 193, 193);
      }
    }
  }
  &__link {
    width: 40px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    height: 25px;
    svg {
      @include svg(22px, 100%, rgb(193, 193, 193));
      &:hover {
        fill: #fff !important;
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    & {
      overflow: hidden;
      position: static !important;
    }
    &__body {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

.bannershop {
  width: 100%;
  height: 280px;
  padding: 25px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background: #af2344;
  &.none {
    display: none;
  }

  &__body {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template: 2fr 1fr / 1fr;
    justify-items: center;
    align-items: center;
    color: #fff;
  }

  &__title {
    grid-row: 1/2;
    justify-self: center;
    align-self: center;
    text-align: center;
    h1 {
      font-weight: 400 !important;
    }
  }
  &__btn {
    grid-row: 2/3;
    justify-self: center;
    align-self: center;
    width: 200px;
  }
}

.goroof {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 30%;
  right: 0%;
  transform: translateX(100%);
  transition: all 0.2s ease 0s;
  cursor: pointer;
  z-index: 0;
  &.show {
    transform: translateX(0%);
  }
  &__arrow {
    width: 100%;
    height: 100%;
    border-right: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    transition: all 0.2s ease 0s;

    svg {
      transform: rotate(-90deg);
      @include svg(100%, 100%, #fff);
      transition: all 0.2s ease 0s;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.359) !important;
      svg {
        transform: rotate(-90deg) rotateX(-180deg);
      }
    }
  }
}

@media screen and (max-width: $mw4 + px) {
  .footer {
    &__inlinear {
      height: 100px;
    }
    &__logo {
      img {
        width: 50px;
        height: 50px;
      }
    }
  }

  .goroof {
    height: 35px;
    bottom: 30%;

    &.show {
      width: 35px;
    }
    &__arrow {
      padding: 5px;
      border: none;
    }
  }
}
@media screen and (max-width: $mw5 + px) {
  .footer {
    &__text {
      width: 100%;
      padding: 20px 0;
    }
    &__link {
      width: 40px;
      svg {
        width: 25px;
      }
    }
    &__items {
      grid-template: 1fr / 1fr 1fr;
    }
  }
  .bannershop {
    padding: 25px 0;
    height: 200px;
    h1 {
      font-size: 28px !important;
    }
    &__btn {
      padding-top: 0px;
    }
    &__title {
      br {
        display: none;
      }
    }
  }
}
