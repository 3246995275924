@import '../../../../style/variable.scss';

.discount {
  display: flex;
  height: 452px;
  width: 100%;
  color: var(--searchBlock);
  &__img {
    width: 40%;
    height: 100%;
    background-image: url('../../../../img/Product/4.jpg');
    background-repeat: no-repeat;
    background-position: 10% center;
    background-size: cover;
  }
  &__body {
    width: 60%;
    padding: 20px;
    display: grid;
    grid-template: auto 1fr 1fr 1fr/ 1fr;

    h1 {
      line-height: 110%;
      text-align: center !important;
      color: var(--oldRed) !important;
    }
  }
  &__title {
    grid-row: 1/2;
  }
  &__text {
    grid-row: 2/3;
    text-align: center;
    padding-top: 10px;
  }
  &__input {
    grid-row: 3/4;
  }
  &__btn {
    padding-top: 20px;
  }
  &__no {
    grid-row: 4/5;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: flex-end;
    p {
      cursor: pointer;
      &:hover {
        color: gray;
      }
    }
  }

  @media screen and (max-width: 520px) {
    & {
      height: 350px;
    }
    &__img {
      display: none;
    }
    &__body {
      width: 100%;
    }
  }
}
