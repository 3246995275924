@import 'src/style/variable.scss';

.btn {
  position: relative;
  text-align: center;
  text-transform: uppercase;

  cursor: pointer;
  display: flex;
  transition: color 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: background 0.8s ease 0s;
  font-family: var(--fontFamily);
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--poblick);
  background: var(--searchBlock);
  border-radius: 2px;
  width: 100%;
}
.btn.sign {
  height: 40px;
  border: 1px solid var(--itemtext);
}
.btn.all {
  height: 40px;
}

.btn.local {
  height: 45px;
  background: var(--oldRed);
  color: #fff;
}

.btn:before {
  content: '';
  display: flex;
  position: absolute;
  right: 0px;
  top: 0;
  width: 0%;
  height: 100%;
  z-index: -1;
  color: #000;
  background: var(--bgcolorLocal);
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  border: none;
  border-radius: 2px;
}

.btn.local:before {
  background: var(--searchBlock);
}

.btn:hover:before {
  left: 0%;
  right: auto;
  width: 100% !important;
}

.btn:hover {
  color: var(--searchBlock) !important;
}
.btn.local:hover {
  color: var(--bgcolorLocal) !important;
}
.btn.sign:before {
  background: var(--poblick);
}

.btn.other {
  background: transparent;
  height: 40px;
  border: 1px solid rgb(213, 213, 213) !important;
  color: rgb(213, 213, 213);
}

.btn.sale {
  background: transparent;
  border: 1px solid var(--itemtext) !important;
  height: 40px;
}
.btn.sale:hover {
  background: transparent !important;
}
.btn.sale:before {
  background: transparent;
}
.btn.gymbtn {
  background: transparent;
  height: 40px;
  background: rgb(213, 213, 213);
  color: var(--oldgray);
}

.btn.gymbtn:before {
  background: #fff;
}

.btnarrow {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 30px;
  color: var(--yellow);

  &__arrow {
    position: relative;
    display: flex;
    align-items: center;
    width: 45px;
    height: 100%;
    margin-right: 10px;
    span:first-child {
      position: absolute;
      right: 0;
      width: 85%;
      height: 1px;
      background: var(--yellow);
      transition: right 0.5s ease 0s;
    }
    span:last-child {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      border-radius: 100%;
      border: 1px solid var(--yellow);
      width: 15px;
      height: 15px;
      transition: left 0.5s ease 0s;
    }
  }
  &__btn {
    .ru {
      display: none;
    }

    &:lang(ru) {
      .en {
        display: none;
      }
      .ru {
        display: block;
      }
    }
  }
  &.old {
    .btnarrow__arrow {
      span:first-child {
        background: var(--oldyellow_2) !important;
      }
      span:last-child {
        border: 1px solid var(--oldyellow_2) !important;
      }
    }
    p {
      color: var(--oldyellow_2) !important;
    }
  }

  &:hover &__arrow {
    span:first-child {
      right: 15%;
    }
    span:last-child {
      left: 65%;
    }
  }
}

@media screen and (max-width: $mw4 + px) {
  .btnarrow {
    &__arrow {
      width: 30px;
      span:first-child {
        width: 85%;
      }
      span:last-child {
        width: 12px;
        height: 12px;
      }
    }
  }
}
