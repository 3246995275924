@import 'src/style/variable.scss';

.menu {
  &__footer {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
}

@media screen and (max-width: 1004px) {
  .menu {
    position: fixed;
    left: -100%;
    width: 100%;
    height: 100%;
    background: #b03d26;
    overflow: hidden auto;
    transition: all 0.5s ease 0s;

    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 4;
    &.show {
      left: 0%;
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      overflow: none;
      width: 100%;
    }
    &__link {
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      width: 100%;
      &::before {
        position: absolute;
        content: '';
        width: 100%;
        pointer-events: none;
        z-index: 1;
      }
      svg {
        width: 30px;
        fill: #fff;
      }
    }
    &__burder {
      order: 0 !important;
    }
    &__profily {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 81px !important;
    }
    &__footer {
      align-items: flex-start;
      width: 100%;
      opacity: 1;
      visibility: visible;
      display: flex;
      justify-content: center;
      height: 81px;
    }
    &__icon {
      margin: 0 10px;
      @include blocksvg(28px, 28px, 100%, 100%, #ffffff);
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      & {
        height: 100vh !important;
        justify-content: space-between;
      }
    }
  }
}
@media (max-width: $mw4 + px) {
  .menu {
    &__profily {
      height: 65px !important;
    }
  }
}
