// </Переменные>
// <Основная сетка>
$mw: 1400;
$mw1: calc($mw + 12);
$mw2: 1100;
$mw3: calc(992 + 12);
$mw4: 769;
$mw5: 479;
.container {
  max-width: $mw + px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  @media (max-width: $mw1 + px) {
    max-width: 1100px;
  }
  @media (max-width: $mw2 + px) {
    max-width: 970px;
  }
  @media (max-width: $mw3 + px) {
    max-width: 750px;
  }
  @media (max-width: $mw4 + px) {
    max-width: none;
    padding: 0px 10px;
  }
}
* {
  font-family: var(--fontFamily);
  padding: 0;
  margin: 0;
  border: 0;
}

span[title] {
  cursor: pointer;
}

*::selection {
  background: var(--oldRed);
}
body {
  text-size-adjust: 100%;
  background: var(--poblick) !important;
}
body.lock {
  overflow: hidden;
}
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:action {
  outline: none;
}
a:focus,
a:action {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}
input,
button,
textarea {
  font-family: var(--fontFamily);
}

input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: innerit;
  font-weight: 400;
}
input[type='text'],
input[type='tel'],
input[type='email'],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
p {
  margin: 0;
  padding: 0;
  font-family: var(--fontFamily);
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
}
p.basketblock {
  margin: 0;
  padding: 0;
  font-family: var(--fontFamily);
  font-size: 16px;
  line-height: 25px;
}

p.mini {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
}

p.big {
  font-size: 18px;
  font-weight: 400;
}

.input {
  display: block;
  border-radius: 0 !important;
  width: 100%;
}
.logo {
  user-select: none;
}
// <Оболочка>

.wrapper {
  width: 100%;
  background: var(--poblick);
  scrollbar-color: #b03d26;
}

.positioncont {
  position: relative;
  left: 0;
  top: 0;
  z-index: 2;
  background: var(--poblick);
}

:root {
  scrollbar-color: #b03d26 #232323;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: #b03d26;
}
::-webkit-scrollbar-track {
  background: #232323;
}

h1 span,
h1 {
  font-size: 55px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: var(--fontFamily);
  line-height: 120%;
}
h2 {
  font-size: 35px;
  text-transform: normal;
  font-family: var(--fontFamily);
  line-height: 130%;
  font-weight: 200;
  text-transform: uppercase;
}
h3 {
  font-size: 25px;
  text-transform: none;
  font-weight: 200;
  font-family: var(--fontFamily);
  line-height: 130%;
}
h4 {
  font-size: 18px;
  font-weight: 500;
  text-transform: normal;
  font-family: var(--fontFamily);
  line-height: 130%;
}
// <Подключение файлов стилей>

// </Подключение файлов стилей>
input {
  width: 100%;
  outline: none;
  font-family: var(--fontFamily);
}
input:focus {
  outline: none;
}

input::placeholder {
  color: var(--placeholder) !important;
  font-size: 15px;
  line-height: 25px;
  text-align: left;
  font-family: var(--fontFamily);
  user-select: none;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: var(--placeholder) !important;
  font-family: var(--fontFamily);
  font-size: 15px;
  line-height: 25px;
  text-align: left;
}
input:focus::placeholder,
textarea:focus::placeholder {
  opacity: 0;
}
.line {
  height: 1px;
  width: 70px;
  justify-self: center;
  align-self: center;
  background: var(--borderInput);
}

.indent_roof {
  padding: calc(45px + (70 - 45) * ((100vw - 320px) / (1100 - 320))) 0
    calc(45px + (70 - 45) * ((100vw - 320px) / (1100 - 320))) 0;
}
.indent {
  padding-bottom: calc(45px + (70 - 45) * ((100vw - 320px) / (1100 - 320)));
}
.indentstart {
  padding-top: calc(140px + (150 - 140) * ((100vw - 320px) / (1100 - 320)));
}
.indent_last {
  padding-bottom: calc(85px + (120 - 85) * ((100vw - 320px) / (1100 - 320)));
}

.paddingheader {
  padding-top: 117px;

  @media screen and (max-width: $mw4 + px) {
    & {
      padding-top: 100px;
    }
    .wrapper {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

@media (max-width: $mw2 + px) {
  h1 span,
  h1 {
    font-size: 50px;
  }
}
@media (max-width: $mw3 + px) {
  .indent {
    padding-bottom: 60px;
  }
  .title {
    padding-bottom: 50px;
  }
  h1 span,
  h1 {
    font-size: 45px;
  }
}

@media (max-width: $mw4 + px) {
  .wrapper {
    overflow: hidden;
  }
  h1 span,
  h1 {
    font-size: 35px;
  }

  p.basket {
    font-size: 14px;
    line-height: 20px;
  }
  h4 {
    font-size: 16px;
  }
  h2 {
    font-size: 30px;
    line-height: 130%;
  }
}

@media (max-width: $mw5 + px) {
  .indent {
    padding-bottom: 40px;
  }
  .title {
    padding-bottom: 35px;
  }
  h1 span,
  h1 {
    font-size: 30px;
    line-height: 120%;
  }
  h2 {
    font-size: 25px;
    line-height: 130%;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 14px;
    line-height: 130%;
  }
  p {
    font-size: 14px;
    line-height: 24px;
  }

  p.mini {
    font-size: 12px;
    line-height: 18px;
  }

  p.big {
    font-size: 16px;
  }
}

@media (max-width: 310px) {
  h1 {
    font-size: 30px;
  }
}
