.phone {
  height: 35px;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  transform: translateY(0%);
  z-index: 3;
  background: var(--oldgray) !important;
  border: none;
  &.show {
    transform: translateY(-100%);
  }
  &__contain {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__body {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    height: 100%;
    color: #2ea3f2 !important;
    cursor: pointer;
    width: auto;
    transition: color 0.2s ease 0s;
    margin-right: 20px;
  }
  &__icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    svg {
      width: 100%;
      height: 100%;
      fill: #2ea3f2;
      transition: fill 0.2s ease 0s;
    }
  }
  &__body:hover {
    color: var(--oldyellow) !important;
    svg {
      fill: var(--oldyellow) !important;
    }
  }
}

.enroll {
  height: 100%;
  cursor: pointer;
  &__body {
    align-items: center;
    display: flex;
    align-items: center;
    height: 100%;
    color: #959595 !important;
    transition: color 0.2s ease 0s;
  }
  &__icons {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    svg {
      width: 100%;
      height: 100%;
      fill: #959595;
      transition: fill 0.2s ease 0s;
    }
  }
  &__body:hover {
    color: var(--oldyellow) !important;
    svg {
      fill: var(--oldyellow) !important;
    }
  }
}

.header {
  position: fixed;
  display: block;
  width: 100%;
  transition: background 0.2s ease 0s, transform 0.5s ease 0s;
  z-index: 3;
  top: 35px;
  background: var(--poblick);

  &.deactive {
    transform: translateY(-100%);
  }
  &__raw {
    width: 100%;
    height: 100%;
  }
  &__wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 81px;
    position: relative;
  }
  &__logo {
    height: 55px;
    display: flex;
    align-items: center;
    user-select: none;
  }

  &__logoText {
    line-height: 100%;
    font-size: 28px;
    user-select: none;
    display: block;
    font-family: var(--fontFamily2) !important;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 0.2s ease 0s;
    color: var(--searchBlock) !important;
  }

  &.home {
    background: #292929bf !important;
    .header__logoText {
      color: #fff !important;
    }
  }
  &.show {
    background: var(--poblick) !important;
    top: 0;
    .header__logoText {
      color: var(--searchBlock) !important;
    }
  }

  &__logoImg {
    padding-right: 10px;
  }
  &__logoImg img {
    width: 55px;
    height: 55px;
    display: block;
    transition: all 0.5s ease 0s;
  }

  &__logo {
    color: #fff !important;
  }

  &__burger {
    z-index: 4;
    position: relative;
    left: auto;
    right: 0;
    opacity: 0;
    visibility: hidden;
    display: none;
    cursor: pointer;
    height: 19px;
    width: 32px;
  }
  &__burger span,
  &__burger:after,
  &__burger:before {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    height: 4px;
    width: 100%;
    background: var(--searchBlock) !important;
    transition: all 0.5s ease 0s;
  }

  &__burger span {
    left: 2px;
  }

  &__burger:after {
    top: 7.5px;
  }
  &__burger:before {
    bottom: 0;
    top: auto;
    left: -2px;
  }

  &__burger.resive:before {
    animation: bottomresive 0.4s linear 0s both;
  }
  &__burger.resive span {
    animation: topresive 0.4s linear 0s both;
  }

  &.home &__burger span,
  &.home &__burger:after,
  &.home &__burger:before {
    background: #fff !important;
  }

  &.home.show &__burger span,
  &.home.show &__burger:after,
  &.home.show &__burger:before {
    background: var(--searchBlock);
  }

  &__burger.show span {
    background-color: #fff !important;
    transition: background-color 0.2s linear 0.2s;
    animation: top 0.4s linear 0s both;
  }
  &__burger.show:after {
    transform: scale(0);
  }
  &__burger.show:before {
    transition: background-color 0.2s linear 0.2s;
    background: #fff !important;
    animation: bottom 0.4s linear 0s both;
  }

  @keyframes topresive {
    0% {
      left: 1px;
      top: 9px;
      transform: rotate(-45deg) translateY(-50%);
    }
    25% {
      left: 0;
    }
    50% {
      top: 50%;
      transform: rotate(0deg) translateY(-50%);
    }
    100% {
      top: 0;
      left: 2px;
      transform: rotate(-0deg) translateY(0%);
    }
  }

  @keyframes bottomresive {
    0% {
      left: -1px;
      bottom: 6px;
      transform: rotate(45deg) translateY(-50%);
    }
    25% {
      left: 0;
    }
    50% {
      bottom: 50%;
      transform: rotate(0deg) translateY(-50%);
    }
    100% {
      bottom: 0;
      left: -2px;
      transform: rotate(0deg) translateY(0%);
    }
  }

  @keyframes top {
    0% {
      left: 2px;
      top: 0;
    }
    25% {
      left: 0;
    }
    50% {
      top: 50%;
      transform: translateY(-50%);
    }
    100% {
      top: 9px;
      left: 1px;
      transform: rotate(-45deg) translateY(-50%);
    }
  }

  @keyframes bottom {
    0% {
      left: -2px;
      bottom: 0;
    }
    25% {
      left: 0;
    }
    50% {
      left: 0;
      bottom: 50%;
      transform: translateY(-50%);
    }
    100% {
      bottom: 6px;
      left: -1px;
      transform: rotate(45deg) translateY(-50%);
    }
  }

  &__line {
    height: 2px;
    width: 100%;
    background: transparent;
    position: relative;
    background: var(--borderforschedul);
  }

  &__linewrap {
    width: 100%;
  }

  &__linesecond {
    background: var(--oldRed);
    height: 100%;
    width: 20px;
    position: absolute;
    transition: transform 0.2s linear 0s;
    z-index: 1;

    &.loaded {
      animation: load 1s linear 0s infinite;
    }

    @keyframes load {
      0% {
        left: -100%;
      }
      100% {
        left: 100%;
        right: auto;
      }
    }
  }

  &.home &__line {
    background: #434343 !important;
  }

  &.home.show &__line {
    background: var(--borderforschedul) !important;
  }

  &.home &__linesecond {
    background: var(--oldRed) !important;
  }

  &.home.show &__linesecond {
    background: var(--oldRed) !important;
  }
}
// .menu {
//   &__secondBlock {
//     display: none;
//   }
// }
