@import 'src/style/variable.scss';

.imgloading {
  position: relative;

  &__loading {
    display: none;
    opacity: 0;
    visibility: hidden;
  }

  &.load {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      @extend %img;
    }

    .imgloading__loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      opacity: 1;
      visibility: visible;
      z-index: 1;
      overflow: hidden;
      @extend %load;
    }
  }
}
