@import 'src/style/variable.scss';

.productbtn {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  opacity: 0;
  transition: all 0.5s ease 0s;

  &__btnold,
  &__btn {
    color: #fff;
    background: var(--oldgray) !important;
    width: 40%;
    height: 100%;
    opacity: 0.8;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      fill: #fff;
    }
    &:hover {
      opacity: 1;
    }
    &:active {
      background: #b03d26;
    }
  }

  &__numberBasket {
    text-align: center;
    flex: 25%;
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1400 - 320)));
    user-select: none;
    color: var(--oldgray) !important;
  }
  &.oneitem &__numberBasket,
  &.oneitem &__numberBasket {
    color: var(--searchBlock) !important;
  }

  &.oneitem &__btnold,
  &.oneitem &__btn {
    color: var(--poblick) !important;
    background: var(--searchBlock) !important;
    border-radius: 2px;
    svg {
      width: 20px;
      height: 20px;
      fill: var(--poblick) !important;
    }
  }

  &.show {
    opacity: 1;
  }
  &__btnold {
    width: 100%;
  }
}
