@import '../../../../style/variable.scss';

.tryfree {
  display: flex;
  height: 450px;
  width: 100%;
  color: var(--searchBlock);
  &__img {
    width: 40%;
    height: 100%;
    background-image: url('../../../../img/gym/oneMan.jpg');
    background-repeat: no-repeat;
    background-position: 20% center;
    background-size: cover;
  }
  &__body {
    width: 60%;
    padding: 20px;
    display: grid;
    grid-template: auto 1fr 1fr/ 1fr;

    h1 {
      line-height: 110%;
      text-align: center !important;
      color: var(--oldRed) !important;
    }
  }
  &__title {
    grid-row: 1/2;
    padding-bottom: 20px;
  }
  &__input {
    grid-row: 2/3;
  }
  &__btn {
    padding-top: 20px;
  }
  &__no {
    grid-row: 3/4;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: flex-end;
    p {
      cursor: pointer;
      &:hover {
        color: gray;
      }
    }
  }

  @media screen and (max-width: 520px) {
    & {
      height: 350px;
    }
    &__img {
      display: none;
    }
    &__body {
      width: 100%;
    }
  }
}
