@import '../../../../../../../style/variable.scss';

.enterbody {
  width: 100%;
  height: 340px;
  display: grid;
  grid-template: 1fr auto / 1fr;
  transition: top 1s ease 0s;
  padding-top: 25px;
  &__title {
    color: var(--itemtext);
    padding-bottom: 20px;
    text-align: left;
  }
  &__body {
    grid-row: 1/2;
    flex: 1;
  }
  &__footer {
    grid-row: 2/3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    cursor: pointer;
    transition: color 0.2s ease 0s;
  }
  &__btn {
    width: 100px;
  }
  &__forget {
    font-size: 14px;
    color: var(--itemtext);
    &:hover {
      color: var(--line);
    }
  }
}
