@import 'src/style/variable.scss';

.heart {
  opacity: 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
  transform: scale(1);
  svg {
    width: 100%;
    height: 100%;
    fill: var(--oldgray) !important;
  }
  &.add svg {
    fill: var(--oldRed) !important;
  }

  &:hover {
    opacity: 1 !important;
    animation: knockHeart 0.5s ease 0s infinite;
  }
  @media (max-width: $mw3 + px) {
    & {
      opacity: 0.8;
    }
  }
  @media (max-width: $mw5 + px) {
    & {
      width: 15px;
      height: 15px;
    }
  }
  @media (max-width: 300px) {
    & {
      width: 20px;
      height: 20px;
    }
  }
}

.product__item:hover .heart {
  opacity: 0.8;
}
.basket .heart,
.oneproduct .heart {
  opacity: 0.8;
  width: 20px;
  height: 20px;
  svg {
    fill: var(--searchBlock) !important;
  }

  &.add {
    opacity: 1;
  }
}

.oneproduct__heart:hover .heart {
  opacity: 1 !important;
  animation: knockHeart 0.5s ease 0s infinite;
}

.basket .heart {
  &:hover {
    opacity: 1;
  }
}

@keyframes knockHeart {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
