@import 'src/style/variable.scss';

.header {
  &__links {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    align-items: center;
    top: 0;
  }
  &__link {
    height: 100%;
    width: 550px;
    display: grid;
    grid-template: 1fr / repeat(5, auto);
  }

  &__link a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    transition: transform 0.2s linear 0s;
  }

  &__link li {
    justify-content: center;
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    user-select: none;
    outline: none;
    border: none;
    grid-column: 1/2;
  }
  &.home &__link li p {
    color: #fff;
  }
  &__link li p {
    color: var(--searchBlock);
    transition: transform 0.2s linear 0s;
  }

  &.show.home &__link li p {
    color: var(--searchBlock);
  }

  &__link li.had p {
    color: var(--oldRed) !important;
  }

  &__link li:nth-child(2) {
    grid-column: 2/3;
  }
  &__link li:nth-child(3) {
    grid-column: 3/4;
  }

  &__link li:nth-child(4) {
    grid-column: 4/5;
  }

  &__link li:nth-child(5) {
    grid-column: 5/6;
  }
  @media screen and (max-width: $mw2 + px) {
    &__link {
      width: 450px;
    }
  }
  @media screen and (max-width: $mw3 + px) {
    &__link {
      width: 550px;
    }
  }
  @media screen and (max-width: 1004px) {
    &__link li.had p {
      color: #fff !important;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &__link {
      align-items: center;
      display: flex;
    }
  }
}
