.loadblock {
  width: 100%;
  background: var(--poblick) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.loading {
  background-image: url('../../../img/icons/loading.gif');
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  overflow: hidden;
  background-size: 20px 20px;
  z-index: 1;
}

.loadblock.all {
  width: 100%;
  display: flex;
  min-height: 100vh;
  height: 100vh;
  justify-content: center;
  align-items: center;
  .loading {
    width: 35px;
    height: 35px;
    background-size: 35px 35px;
  }
}

.btnloading {
  height: 40px;
  width: 100%;
  border: 1px solid var(--itemBorder) !important;
  border-radius: 2px;
}
