@import '../variable.scss';
.sppiner {
  position: absolute;
  display: none;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  overflow: hidden;

  @extend %load;

  .sppiner.load {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
