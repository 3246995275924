@import 'src/style/variable.scss';

.priceList {
  background: var(--gray);
  &__row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-bottom: calc(35px + 25 * (100vw - 320px) / 780);
  }

  &__items {
    width: 100%;
    display: grid;
    grid-template: 1fr/ 1fr 5% 1fr 5% 1fr;
  }
  &__item:nth-child(1) {
    grid-column: 1/2;
  }
  &__item:nth-child(2) {
    grid-column: 3/4;
  }
  &__item:nth-child(3) {
    grid-column: 5/6;
  }
  &__item {
    background: linear-gradient(to top right, #fff, rgb(209, 209, 209));
    width: 100%;
    border: 2px solid #fff;
    display: grid;
    grid-template: auto auto 1fr / 1fr;
    border-radius: 5%;
    padding-bottom: 30px;
    h2 {
      color: var(--oldgray) !important;
    }
  }

  &__item:last-child {
    border-color: #fff9a6;
  }

  &__header {
    background: var(--oldgray);
    width: 100%;
    display: flex;
    grid-row: 2/3;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    position: relative;
    .arrow {
      display: none;
      width: 15px;
      height: 15px;
      align-items: center;
      justify-self: flex-end;
      fill: #fff;

      svg {
        width: 100%;
        height: 100%;
        transition: transform 0.3s linear 0s;
        transform: rotate(0deg);
      }
    }
  }

  &__subtitle {
    color: #fff;
    text-align: center;
    span {
      font-size: 14px;
    }
  }

  &__title {
    padding: 10px 0;
    text-align: center;
    row-gap: 1/2;
  }

  &__body {
    color: var(--oldgray);
    padding: 20px 20px 0 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-row: 3/4;
  }

  &__text {
    width: 100%;
    margin-bottom: 10px;
    display: grid;
    grid-template: 1fr / 25px 1fr;
    p {
      grid-column: 1/2;
      width: 100%;
    }
    p:last-child {
      grid-column: 2/3;
    }
  }

  &__footer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    p {
      color: var(--oldgray);
    }
    p:last-child {
      font-style: italic;
    }
    p:first-child {
      font-weight: bold !important;
    }
  }

  &__text:last-child {
    margin: 0;
  }

  &__svg {
    font-size: 25px;
  }

  &__item:last-child {
    margin-right: 0px;
  }

  @media screen and (max-width: $mw2 + px) {
    &__items {
      grid-template: 1fr/ 1fr 10px 1fr 10px 1fr;
    }
  }

  @media screen and (max-width: $mw3 + px) {
    &__items {
      grid-template: 1fr 10px 1fr/ 1fr 10px 1fr;
    }
    &__item:nth-child(1) {
      grid-column: 1/2;
      grid-row: 1/2;
    }
    &__item:nth-child(2) {
      grid-column: 3/4;
      grid-row: 1/2;
    }
    &__item:nth-child(3) {
      grid-column: 1/2;
      grid-row: 3/4;
    }
  }

  @media screen and (max-width: $mw4 + px) {
    .arrow {
      display: flex;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
    &__items {
      display: flex;
      flex-direction: column;
      margin: 0;
    }
    &__item {
      border-radius: 5px !important;
      margin-bottom: 20px;
      width: 100%;
      height: auto;
      max-width: 100%;
    }

    &__header {
      cursor: pointer;
      padding: 10px;
    }
    &__header.add {
      svg {
        transform: rotate(90deg);
      }
    }
    &__body {
      padding: 10px;
      opacity: 0;
      visibility: hidden;
      max-height: 0px;
      height: auto;
      transition: all 0.4s cubic-bezier(0.83, 0.28, 0.12, 0.64) 0s;
      overflow: hidden;
    }
    &__body.add {
      opacity: 1;
      visibility: visible;
      max-height: 500px;
    }
  }
}
.canfree {
  width: 100%;
  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__text {
    color: var(--searchBlock);
    padding-right: 20px;
  }
}
