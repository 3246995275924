@import 'src/style/variable.scss';

.inputFiled {
  width: 100%;

  .hidden {
    display: none;
  }

  .inputFiled.focus &__background {
    border-bottom: 3px solid #307041;
  }
}

.inputFiled:last-child {
  padding: 0;
}

.inputFiled__title {
  padding-bottom: 11px;
  font-family: var(--fontFamily);
  line-height: 20px;
  color: var(--itemtext) !important;
  user-select: none;
}

.inputFiled__block {
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: var(--poblick) !important;
  border: 1px solid var(--itemBorder) !important;
  overflow: hidden;
  border-radius: 2px;
}

.inputFiled__block input {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding-left: 10px;
  background: transparent !important;
  font-weight: 500;
  font-size: 16px;
  color: var(--itemtext) !important;
  border: none;
}

.inputFiled__text,
.inputFiled__tel {
  position: absolute;
  top: auto;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--itemBorder) !important;
  transition: left 0.5s ease 0s;
  pointer-events: none;
  user-select: none;
}

.inputFiled__text.old {
  P {
    display: block;
  }
}

.inputFiled__text {
  P {
    display: none;
  }
}

.inputFiled__text.tel {
  left: 35px;
}

.inputFiled__text.all.focus {
  left: 25px;
}

.inputFiled__text.tel.focus {
  left: 55px;
}

.inputFiled__text.tel + input {
  padding-left: 35px;
}

.inputFiled__eye {
  position: absolute;
  width: 18px;
  height: 18px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
  cursor: pointer;
  border: none;
  background: transparent;
}

.inputFiled__eye svg {
  fill: var(--itemBorder) !important;
  width: 100%;
  height: 100%;
  background: transparent;

  &:hover {
    fill: var(--itemtext) !important;
  }
}

.inputFiled__footer {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  height: 50px;
}

.inputFiled__error {
  letter-spacing: 0.5px;

  p {
    color: #c7331f !important;
    line-height: 18px !important;
  }
}

@media screen and (max-width: $mw5 + px) {
  .inputFiled {
    &__background {
      background: rgb(255, 255, 255);
      height: 40px;
    }
    .inputFiled__footer {
      height: 40px;
    }
    &__text {
      font-size: 14px;
    }

    &__text.all.focus {
      font-size: 12px;
    }

    &__eye {
      width: 15px;
      height: 15px;
      svg {
        fill: var(--itemtext) !important;
      }
    }
    .inputFiled__block input {
      font-size: 14px;
    }

    &__footer {
      height: 50px;
    }
  }
}
@media screen and (max-width: 490px) {
  .inputFiled__error {
    p {
      font-size: 10px;

      line-height: 12px !important;
    }
  }
}
