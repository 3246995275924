@import 'src/style/variable.scss';

.login {
  width: 100%;
  min-height: 420px;
  padding: 30px 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  &__close {
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
  }
  &__x {
    width: 20px;
    height: 20px;
    cursor: pointer;
    svg {
      fill: var(--itemtext);
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 490px) {
    & {
      padding: 20px 10px 0 10px;
    }
  }
}
