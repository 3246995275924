.lang {
  .ru {
    display: none;
  }

  &:lang(ru) {
    .en {
      display: none;
    }
    .ru {
      display: block;
    }
  }

  &.link {
    text-transform: uppercase;
    font-family: var(--fontTitle);
    letter-spacing: 0.5px;
  }

  @media screen and (max-width: 1004px) {
    &.link {
      text-transform: none;
      font-size: calc(18px + (25 - 18) * ((100vw - 320px) / (1400 - 320)));
      line-height: 130%;
      color: #fff !important;
    }
  }
}
