.title h1 {
  width: 100%;
  max-width: 400px;
}

.title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  user-select: none;
  text-transform: uppercase;
  color: var(--searchBlock);
  padding-bottom: calc(35px + (60 - 35) * ((100vw - 320px) / (1100 - 320)));
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title span {
  width: 30%;
  background: var(--borderInput);
  height: 1px;
}
