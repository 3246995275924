.winload {
  width: 100%;
  height: 100;
  top: 0;
  right: 0;
  height: 100%;
  //   position: fixed;
  background: var(--poblick);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  //   overflow: hidden;
  display: none;
  opacity: 0;
  visibility: hidden;
  &.load {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
