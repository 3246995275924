@import '../../../../../../../style/variable.scss';

.upbody {
  width: 100%;
  height: 100%;
  transition: top 1s ease 0s;
  padding-top: 25px;
  &__name {
    display: flex;
    display: grid;
    grid-template: 1fr/ 1fr 20px 1fr;

    div {
      grid-column: 1/2;
    }

    div:last-child {
      grid-column: 3/4;
    }
  }

  &__body {
    flex: 1;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    &:hover {
      color: var(--line);
    }
  }
  &__btn {
    width: 100px;
  }

  @media screen and (max-width: 490px) {
    &__name {
      display: flex;
      display: grid;
      grid-template: 1fr 1fr/ 1fr;

      div {
        grid-row: 1/2;
        grid-column: 1/2;
      }

      div:last-child {
        grid-row: 2/3;
        grid-column: 1/2;
      }
    }
    &__footer {
      margin-top: 0px;
    }
  }
}
